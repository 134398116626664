import { useState } from "react";
import { colorCodeRanking } from "helpers/stormtechColorCodes";
import { getSearchClient } from "services/typesense";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useDatabaseReader } from "database/useDatabaseReader";

const useSelectedArticle = () => {
  const { warehouses, articleSearchKey } = useDatabaseReader();

  const [selectedArticleData, setSelectedArticleData] = useState([]);
  const [currentInventoryLoading, setCurrentInventoryLoading] = useState(false);
  const [currentInventory, setCurrentInventory] = useState({});
  const [selectedIndex, setSelectedIndex] = useState(0);

  const onSelectStyle = async (style) => {
    setCurrentInventory([]);
    setCurrentInventoryLoading(true);

    const searchTerm = ["W", "Y", "S", "M", "L"].includes(
      style.value[style.value.length - 1],
    )
      ? style.value.slice(0, -1)
      : style.value;

    const searchParameters = {
      q: searchTerm,
      query_by: "itemNumber",
      // include_fields: "itemNumber, name, color, price, sizes",
      sort_by: "itemNumber:asc",
      page: 1,
      per_page: 30,
      group_by: "itemNumber",
      group_limit: 30,
    };

    const searchClient = getSearchClient(articleSearchKey);
    const collection = ["local"].includes(
      process.env.REACT_APP_USER_ENVIRONMENT,
    )
      ? "staging.articles"
      : "production.articles";
    const results = await searchClient
      .collections(collection)
      .documents()
      .search(searchParameters);

    const finalResult = [];

    results.grouped_hits.forEach((group) => {
      const groupHits = [];
      group.hits.map((hit) =>
        groupHits.push({
          ...hit.document,
        }),
      );

      //Sort the colors
      const singleColor = [];
      const multipleColors = [];
      const clearanceColors = [];

      groupHits.forEach((hit) => {
        if (
          (hit.secondaryColorCode == null ||
            hit.primaryColorCode === hit.secondaryColorCode) &&
          !hit.isClearance
        ) {
          singleColor.push(hit);
        } else if (!hit.isClearance) {
          multipleColors.push(hit);
        } else {
          clearanceColors.push(hit);
        }
      });

      singleColor.sort(
        (a, b) =>
          colorCodeRanking.indexOf(a.primaryColorCode) -
          colorCodeRanking.indexOf(b.primaryColorCode),
      );

      multipleColors.sort(
        (a, b) =>
          colorCodeRanking.indexOf(a.primaryColorCode) -
            colorCodeRanking.indexOf(b.primaryColorCode) ||
          colorCodeRanking.indexOf(a.secondaryColorCode[0]) -
            colorCodeRanking.indexOf(b.secondaryColorCode[0]),
      );

      clearanceColors.sort(
        (a, b) =>
          colorCodeRanking.indexOf(a.primaryColorCode) -
          colorCodeRanking.indexOf(b.primaryColorCode),
      );

      const sortedGroupHits = [
        ...singleColor,
        ...multipleColors,
        ...clearanceColors,
      ];
      // console.log(sortedGroupHits);

      finalResult.push({
        itemNumber: group.group_key[0],
        colors: sortedGroupHits,
      });
    });

    setSelectedArticleData(finalResult);
    setSelectedIndex(
      finalResult.findIndex((result) => result.itemNumber === style.value),
    );

    const getInventory = httpsCallable(getFunctions(), "onGetInventory");

    await Promise.all(
      finalResult.map(async ({ itemNumber, colors }, finalResultIndex) => {
        return Promise.all(
          colors.map(async (colorData, colorIndex) => {
            const sizeSkus = [];
            colorData.sizes.forEach((sizeData) => {
              sizeSkus.push(sizeData.sku);
            });
            const inventoryData = await getInventory({
              skus: sizeSkus,
              warehouses: warehouses,
            });

            //Keep track of 2XS, 6XL, TALL LG & TALL XL to integrate into incoming stock
            const outlierSizesIncoming = {
              firstIndex: finalResultIndex,
              secondIndex: colorIndex,
              warehouses: {},
            };

            const parsedInventoryData = { ...inventoryData.data };
            //Make adjustments for oversold current inventory
            //Only use values from PDF if they are negative
            colorData.sizes.forEach((sizeData) => {
              warehouses.forEach((warehouseData) => {
                if (
                  parsedInventoryData[sizeData.sku][warehouseData.letterCode]
                    .onHand === 0 &&
                  colorData.pdfCurrentInventory[warehouseData.letterCode][
                    sizeData.name
                  ] < 0
                ) {
                  parsedInventoryData[sizeData.sku][
                    warehouseData.letterCode
                  ].onHand =
                    colorData.pdfCurrentInventory[warehouseData.letterCode][
                      sizeData.name
                    ];
                }

                if (
                  (sizeData.name === "2XS" ||
                    sizeData.name === "6XL" ||
                    sizeData.name === "TALL LG" ||
                    sizeData.name === "TALL XL") &&
                  parsedInventoryData[sizeData.sku][warehouseData.letterCode]
                    .incomingDate
                ) {
                  if (
                    outlierSizesIncoming.warehouses[warehouseData.letterCode] ==
                    null
                  )
                    outlierSizesIncoming.warehouses[warehouseData.letterCode] =
                      {};
                  outlierSizesIncoming.warehouses[warehouseData.letterCode][
                    sizeData.name
                  ] = {
                    date: parsedInventoryData[sizeData.sku][
                      warehouseData.letterCode
                    ].incomingDate,
                    qty: parsedInventoryData[sizeData.sku][
                      warehouseData.letterCode
                    ].quantityToReceive,
                  };
                }
              });
            });
            setCurrentInventory((prevData) => ({
              ...prevData,
              ...parsedInventoryData,
            }));

            // console.log(outlierSizesIncoming);
            if (Object.keys(outlierSizesIncoming.warehouses).length > 0) {
              setSelectedArticleData((prev) => {
                const data = [...prev];
                // console.log(data);
                Object.entries(outlierSizesIncoming.warehouses).forEach(
                  ([warehouseCode, incomingData]) => {
                    // console.log(warehouseCode);
                    Object.entries(incomingData).forEach(([size, sizeData]) => {
                      const date = new Date(sizeData.date);
                      //Adding 1 to the day below to line up with the PDF data
                      const formattedDate = `${date.getMonth() + 1}/${
                        date.getDate() + 1
                      }/${date.getFullYear()}`;
                      // console.log(formattedDate);
                      // console.log(warehouseCode);
                      // console.log(
                      //   data[outlierSizesIncoming.firstIndex].colors[
                      //     outlierSizesIncoming.secondIndex
                      //   ]?.incomingInventory
                      // );
                      // console.log("qty:", sizeData.qty);
                      const index = data[
                        outlierSizesIncoming.firstIndex
                      ].colors[
                        outlierSizesIncoming.secondIndex
                      ].incomingInventory?.[warehouseCode]?.findIndex(
                        (element) => element.date === formattedDate,
                      );
                      // console.log(index);
                      if (index === -1) {
                        data[finalResultIndex].colors[
                          colorIndex
                        ].incomingInventory[warehouseCode].push({
                          date: formattedDate,
                          hasContainerNumber: false,
                          incomingBySize: {
                            [`${size}`]: sizeData.qty,
                          },
                          type: "OCEAN",
                        });
                      } else if (index == null) {
                        data[finalResultIndex].colors[
                          colorIndex
                        ].incomingInventory[warehouseCode] = [
                          {
                            date: formattedDate,
                            hasContainerNumber: false,
                            incomingBySize: {
                              [`${size}`]: sizeData.qty,
                            },
                            type: "OCEAN",
                          },
                        ];
                      } else {
                        data[finalResultIndex].colors[
                          colorIndex
                        ].incomingInventory[warehouseCode][index][
                          "incomingBySize"
                        ][size] = sizeData.qty;
                      }
                    });
                  },
                );
                return data;
              });
            }
          }),
        );
      }),
    );

    // console.log("Done getting inventory");
    // console.log(currentInventory);

    setCurrentInventoryLoading(false);
  };

  return {
    selectedArticleData,
    currentInventory,
    currentInventoryLoading,
    selectedIndex,
    setSelectedIndex,
    onSelectStyle,
  };
};

export default useSelectedArticle;
